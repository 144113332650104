import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { CommonService } from "./../../../../../services/common.service";
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { cloneDeep } from "src/app/utils";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { SupplierFinancial } from "src/app/interfaces/supplierProfile";
import VMController from "src/app/interfaces/vm";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { supplierGeographical } from "src/app/shared/shared";
import { AllTaskKey } from "src/app/interfaces/workflow";
import { AuthService } from "src/app/services/auth/auth.service";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { MetadataService } from "src/app/services/metadata.service";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { trim } from "src/app/utils";
import { SupplierCommonService } from "../../../services/supplierCommon.service";
import { BuyerService } from "src/app/services/buyer.service";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";
import { showGlobalPrepopulateData } from "../../../../../../config/config";
import { SupplierService } from "../../../../../services/supplier.service";
import { MatDialog } from '@angular/material/dialog';
import { GlobalPopupComponent } from "src/app/global-popup/global-popup.component";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
import { SupplierProfile } from "../../../../../interfaces/supplierProfile";

@Component({
  selector: "app-financial",
  templateUrl: "./financial.component.html",
  styleUrls: ["./financial.component.sass"],
})
export class FinancialComponent implements OnInit, AfterViewInit {
  isTsmId = false;
  getConfigsInfo: ComponentConfig[] = [];
  originalConfig: ComponentConfig[] = [];
  context: DynamicContext;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;

  isValidated: boolean;
  isDisplayRequierdVerify = false;
  isNew = true;
  isNewVersion: boolean = true;
  globalAnwsers = [];
  globalNameElement:any;
  dialogRef: any;
  getglobalVal: any;
  firsttimeSave: boolean = false;
  get financialInfo(): SupplierFinancial {
    this.profileService.supplierModel.SupplierProfile.SupplierFinancial.RequestorEmailAddress =
      this.profileService.supplierModel.Mapping.requestor.createdBy;
    return this.profileService.supplierModel.SupplierProfile.SupplierFinancial;
  }

  set financialInfo(val) {
    this.profileService.supplierModel.SupplierProfile.SupplierFinancial = val
  }

  get isView() {
    if (!this.profileService.isView) {
      this.getConfigsInfo = this.originalConfig;
    }
    return this.profileService.isView;
  }

  vm: VMController<SupplierFinancial> = new VMController();
  isResubmitDisabled = true;

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private commonService: CommonService,
    private dialogService: DialogService,
    private supplierService: SupplierService,
    private loadingService: LoadingService,
    private metadataService: MetadataService,
    private onBoardingService: OnBoardingService,
    private auth: AuthService,
    private supplierCommonService: SupplierCommonService,
    private buyerService: BuyerService,
    private languageService: LanguageService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.profileService.stepChangeEvent.emit(3);
    this.isTsmId = !!this.profileService.supplierModel.Mapping.TsmId;
    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
    })
  }

  refreshToggle():void{
    this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true";
  }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.profileService.current_step = 3;
    this.context = new DynamicContext();
    // load current login user's task
    if (this.isView) {
      this.context.mode = "formView";
    }

    await this.profileService.loadUserTasks();
    await this.supplierCommonService.SetContextValue(this.context);
    const paymentTermListparams = {
      companyCode:
        this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
          .CompanyCode,
      geographicalUnit:
        this.profileService.supplierModel.Mapping.geographicalUnit,
      category:
        this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCategoryGroup,
      spendCommodityGroup:
        this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCommodityGroup,
      enterpriseType:
        this.profileService.supplierModel.SupplierProfile.SupplierOrganization
          .EnterpriseType,
    };
    const result: any = await this.buyerService.getPaymentTermList(
      paymentTermListparams
    );
    if (result && result.isSuccess) {
      this.context.set("standardPaymentTerms", result.data);
    }

    this.getConfigsInfo = await this.metadataService.getPageConfiguration(
      "supplier-profile-step3"
    );
    if(this.context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SpendCommodityGroup == '22'){
      const configsExcludingOrderingMethod = this.getConfigsInfo.filter(x => x.label !== "Ordering Method");
      this.getConfigsInfo = configsExcludingOrderingMethod;
    }
    this.originalConfig = cloneDeep(this.getConfigsInfo);
    this.context.set(
      "parentModel",
      cloneDeep(this.profileService.supplierModel)
    );
    this.context.set("originalFinancialInfo", cloneDeep(this.financialInfo));
    if (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.PH
    ) {
      this.financialInfo.PreferredOrderingMethod = "Print";
    }
    this.commonService.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        e.returnValue = "";
      }
    });

    if (!this.isView) {
      this.dynamicForm.subscribeToFormChange(() => {
        setTimeout(() => {
          this.isResubmitDisabled =
            JSON.stringify(this.dynamicForm.originalValues()) ===
            JSON.stringify(this.dynamicForm.values());
        });
      });
    }
    this.loadingService.hideLoading();
  }

  async ngAfterViewInit() {
    if (!this.isView && showGlobalPrepopulateData ){
      this.formAutofill();
    }
    this.AMLValidation();
  }

  async AMLValidation() {
    console.log('Financial ngAfterViewInit Called');
    if(!this.isView) {
      this.dynamicForm.executeAfterFormGroupInit(() => {
        console.log('ngAfterViewInit Before setTimeout');
        setTimeout(() => {
          console.log("ngAfterViewInit this.dynamicForm['configMap']", this.dynamicForm['configMap']);
          if (this.profileService.supplierModel.SupplierProfile.SupplierFinancial) {
            console.log("ngAfterViewInit values 11", this.profileService.supplierModel.SupplierProfile.SupplierFinancial);
            if (this.dynamicForm.formGroup.controls['PaymentBankingInformationList'].value.length) {
              let supplierRegisteredCountryValue = this.profileService.supplierModel.SupplierProfile.SupplierOrganization.SupplierRegisteredCountry;
              let companyNameValue = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName;
              let bankCountryValue = this.dynamicForm.formGroup.controls['PaymentBankingInformationList'].value[0].BankCountry;
              let accountHolderNmValue = this.dynamicForm.formGroup.controls['PaymentBankingInformationList'].value[0].AccountHolderNm;
              console.log("ngAfterViewInit All Values", supplierRegisteredCountryValue, companyNameValue, bankCountryValue, accountHolderNmValue);
              this.dynamicForm['configMap'].forEach(config => {
                if (this.profileService.supplierModel.SupplierProfile.SupplierFinancial["BankCountryAndRegisteredCountryValidation"] &&
                  this.profileService.supplierModel.SupplierProfile.SupplierFinancial["BankCountryAndRegisteredCountryValidation"].length &&
                  config.name == 'BankCountryAndRegisteredCountryValidation') {
                  console.log('ngAfterViewInit configMap BankCountryAndRegisteredCountryValidation: ', config)
                  if (supplierRegisteredCountryValue && bankCountryValue) {
                    let BankCountryText, SupplierRegisteredCountryText;
                    if(bankCountryValue === 'Ca'){
                      BankCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${bankCountryValue.toUpperCase()}.Text`);
                    }else{
                      BankCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${bankCountryValue}.Text`);
                    }
                    if(supplierRegisteredCountryValue === 'Ca'){
                      SupplierRegisteredCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${supplierRegisteredCountryValue.toUpperCase()}.Text`);
                    }else{
                      SupplierRegisteredCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${supplierRegisteredCountryValue}.Text`);
                    }
                    config.hide = supplierRegisteredCountryValue.toLowerCase().trim() == bankCountryValue.toLowerCase().trim()
                    config.label = `Supplier Location { ${SupplierRegisteredCountryText} } does not match with Bank Country/Location { ${BankCountryText} }. Select any one of the Reasons-`
                    config.required = !config.hide
                    console.log("ngAfterViewInit Country IF", config);
                  }
                }
                if (this.profileService.supplierModel.SupplierProfile.SupplierFinancial["CompanyNameAndAccountHolderNameValidation"] &&
                  this.profileService.supplierModel.SupplierProfile.SupplierFinancial["CompanyNameAndAccountHolderNameValidation"].length &&
                  config.name == 'CompanyNameAndAccountHolderNameValidation') {
                  console.log('ngAfterViewInit configMap CompanyNameAndAccountHolderNameValidation: ', config)
                  if (accountHolderNmValue && companyNameValue) {
                    config.hide = accountHolderNmValue.toLowerCase().trim() == companyNameValue.toLowerCase().trim()
                    config.label = `Supplier Name { ${companyNameValue} } does not match with Bank Account Holder’s Name { ${accountHolderNmValue} }. Select any one of the Reasons-`
                    config.required = !config.hide
                    console.log("ngAfterViewInit Name IF", config);
                  }
                }
              })
            }
          }
          console.log('ngAfterViewInitthis.dynamicForm.formGroup.controls', this.dynamicForm.formGroup.controls);
          localStorage.setItem('dynamicFinancialForm' , JSON.stringify(this.dynamicForm.originalValues()))
        });
      })
    }
  }

  async formAutofill() {
    let payload = {
      supplierCode: this.profileService.supplierCode,
      SupplierCompanyName: this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName,
      supplierTsmId: this.profileService.supplierModel.Mapping.TsmId,
      countryServed: this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry,
      RoleCode: this.auth.passport.supplierMember.RoleCode
    }

    if(payload.supplierCode && payload.supplierTsmId){
      let getGlobalQAs;
      if (localStorage.getItem("globalqa") == null) {
        const getGlobalQAs = await this.supplierService.getGlobalFieldData(payload);
        localStorage.setItem("globalqa", JSON.stringify(getGlobalQAs))
      }
      else {
        getGlobalQAs = JSON.parse(localStorage.getItem("globalqa"));
      }
      if (this.getConfigsInfo && this.getConfigsInfo.length && getGlobalQAs) {
        this.getConfigsInfo.forEach(item => {
          item['fieldset'].forEach(field => {

            if (field['global']) {
              const filterAnwsers = getGlobalQAs.filter((question) => question['QuestionName'] == field['name'])
              this.globalAnwsers.push(...filterAnwsers)
            }
          })
        })
      }
      let financialInfoData = Object.assign({}, this.financialInfo)
      this.globalAnwsers.forEach((anwserItem) => {
        if (anwserItem != null && anwserItem != undefined) {
          if((this.financialInfo[anwserItem['QuestionName']] == "") || (this.financialInfo[anwserItem['QuestionName']] == null) || (this.financialInfo[anwserItem['QuestionName']] == undefined)){
            financialInfoData[anwserItem['QuestionName']] = anwserItem['Answer']
          }
        }
      })
      this.financialInfo = financialInfoData
      setTimeout(() => {
        console.log('formAutofill Calling updateFormValues');
        this.dynamicForm.updateFormValues()
      });
    }
    setTimeout(() => {
      // In Future need to get localStorage value from database when userlogin into the system 
      if(!localStorage.getItem('financialsaved') && localStorage.getItem('financialsaved') !== 'yes') {
        this.SaveFirsttime();
      }
      console.log("settimeout");
      const elements: HTMLInputElement[] = [];

      for (let gobalVal of this.globalAnwsers) {
        let formControlType: string;

        if (gobalVal['AnswerInputField'] === 'dropdown') {
          formControlType = 'select';
        } else if (gobalVal['AnswerInputField'] === 'input') {
          formControlType = 'input';
        } else if (gobalVal['AnswerInputField'] === 'radio') {
          formControlType = 'radio';
        }

        const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);

        if (actualElement) {
          elements.push(actualElement);
        }
      }

      for (const element of elements) {
        element.addEventListener("change", (e) => {
          const targetElement = e.currentTarget as HTMLElement;
          if (targetElement) {
            targetElement.style.border = 'solid 1px #414187';
          }
        });
      }
    });
  }

  highlightInput() {
    const elements: HTMLInputElement[] = [];

    for (let gobalVal of this.globalAnwsers) {
      let formControlType: string;

      if (gobalVal['AnswerInputField'] === 'dropdown') {
        formControlType = 'select';
      } else if (gobalVal['AnswerInputField'] === 'input') {
        formControlType = 'input';
      } else if (gobalVal['AnswerInputField'] === 'radio') {
        formControlType = 'radio';
      }

      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);

      if (actualElement) {
        elements.push(actualElement);
      }
    }''

    for (const element of elements) {
      element.style.border = '2px solid orange';
    }
  }
  async SaveFirsttime(): Promise<boolean> {
    this.profileService.supplierModel.SupplierProfile.SupplierFinancial = trim(
      this.dynamicForm.values()
    );
    if (this.isNewVersion) {
      this.firsttimeSave = true;
      this.bindDataToSupplierProfileModel();
      await this.saveNewDraft();
    } else {
      await this.saveExistingDraft();
    }
    localStorage.setItem('financialsaved', 'yes');
    this.dynamicForm.updateFormValues();
    this.firsttimeSave = false;
    this.loadingService.closeLoading();
    this.loadingService.hideLoading();
    return true;
  }
  openGlobalPopup() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      for (this.getglobalVal of this.globalAnwsers) {
        const supplierControl = this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
        if (supplierControl) {
          if (supplierControl.dirty) {
            if (result === 'onConfirmClick') {
              const routerPath: string = this.isTsmId
                ? `supplier/profile/${this.profileService.supplierCode}/step5`
                : `supplier/profile/${this.profileService.supplierCode}/step4`;

              this.router.navigate([routerPath]);
            } else if (result === 'onCancelClick') {
              supplierControl.setValue(this.getglobalVal.Answer);
              this.highlightInput();
            }
          }
        }
      }
    });
  }

  checkAndOpenGlobalPopup() {
    const globalDirty = this.globalAnwsers.some(globalAnswerVal => {
      const control = this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
      return control && control.dirty;
    });
    if (globalDirty) {
      this.openGlobalPopup();
    }
    else if(!globalDirty){
      const routerPath: string = this.isTsmId
        ? `supplier/profile/${this.profileService.supplierCode}/step5`
        : `supplier/profile/${this.profileService.supplierCode}/step4`;

      this.router.navigate([routerPath]);
    }
  }

  async canDeactivate() {
    if (this.isView) {
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirm();
      if (result === "save") {
        if (!(await this.saveDraft())) {
          return false;
        }
      }
      if (result === "cancel") {
        return false;
      }
    }

    return true;
  }

  async btnNext() {
    const routerPath: string = this.isTsmId
      ? `supplier/profile/${this.profileService.supplierCode}/step5`
      : `supplier/profile/${this.profileService.supplierCode}/step4`;

    this.router.navigate([routerPath]);
  }

  validate() {
    return this.dynamicForm.valide();
  }

  async resubmit() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return;
    }
    this.validationForAML();
    // resubmit
    if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      this.resubmitForm().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.finanical.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.finanical.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    } else {
      this.changeRequest().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.finanical.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.finanical.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    }
  }

  async saveAndNavigate() {
    this.validationForAML();
    this.saveDraft().then((data: boolean) => {
      console.log("saveAndNavigate saveDraft Called data", data);
      if (data) {
        this.vm.saved();
        this.checkAndOpenGlobalPopup();
      }
    });
  }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    this.validationForAML();
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    this.profileService.getResubmitTask();
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel()
      await this.savenewresubmit();
    } else {
      await this.saveExistingresubmit();
    }
    return true;
  }
  async saveExistingresubmit() {
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.resubmitForm();
  }
  async savenewresubmit() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.supplierProfileResubmit}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.newresubmitForm();
    this.loadingService.closeLoading()
  }
 

  bindDataToSupplierProfileModel() {
    this.profileService.createSupplierProfileModel();
    this.profileService.supplierProfileModel.SupplierProfile.SupplierFinancial = this.profileService.supplierModel.SupplierProfile.SupplierFinancial;
    this.profileService.supplierProfileModel.Mapping = this.profileService.supplierModel.Mapping;
    // let initalSupplierModel: SupplierFinancial = trim(this.dynamicForm.originalValues());
    let  initalSupplierModel = localStorage.getItem('dynamicFinancialForm') ? trim(JSON.parse(localStorage.getItem('dynamicFinancialForm'))) : trim(this.dynamicForm.originalValues());
    let currentValues: SupplierFinancial = trim(this.dynamicForm.values());
    // In Future need to get localStorage value from database when userlogin into the system 
    // and this change is done as part of 555132 Bug
    if(this.firsttimeSave) {
      this.profileService.supplierProfileModel.ChangeHistory = this.commonService.getChangeHistoryForFirstSave(initalSupplierModel, currentValues, "SupplierFinancial");
    } else {
      this.profileService.supplierProfileModel.ChangeHistory = this.commonService.getChangeHistory(initalSupplierModel, this.profileService.supplierProfileModel.SupplierProfile.SupplierFinancial, "SupplierFinancial")
    }
  }

  // resubmit request form
  async changeRequest(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      await this.newChangeRequestform();
    } else {
      await this.existingChangeRequestform();
    }
    return true;
  }

  async newChangeRequestform(){
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.newRequestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.newchangeRequestForm(),
      this.loadingService.closeLoading()
  }

  async existingChangeRequestform(){
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.requestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.changeRequestForm();
  }

  // save draft request form
  async saveDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      if (JSON.stringify(this.profileService.supplierProfileModel.ChangeHistory)!="{}") {
        await this.saveNewDraft();
      }
    } else {
      await this.saveExistingDraft();
    }
    this.loadingService.closeLoading();
    this.loadingService.hideLoading();
    return true;
  }

  async saveExistingDraft() {
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.saveDraft();
  }

  async saveNewDraft() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.financialSaveDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.saveDraftForFinancial();
  }

  async validationForAML() {
    let supplierRegisteredCountryValue = this.profileService.supplierModel.SupplierProfile.SupplierOrganization.SupplierRegisteredCountry;
    let bankCountryValue = this.dynamicForm.formGroup.controls['PaymentBankingInformationList'].value[0].BankCountry;
    let accountHolderNmValue = this.dynamicForm.formGroup.controls['PaymentBankingInformationList'].value[0].AccountHolderNm;
    let companyNameValue = this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName;
    this.getConfigsInfo.forEach(data => {
      if (data != null) {
        data['fieldset'].forEach(arr => {
          if (arr.type == 'layout') {
            for (var Field of arr.fieldset) {
              if (Field && Field.name == 'AMLValidationDecision') {
                if (Field.fieldset != null || Field.fieldset != undefined) {
                  Field.fieldset.forEach(item => {
                    if (item.name == "BankCountryAndRegisteredCountryValidation") {
                      if (supplierRegisteredCountryValue && bankCountryValue) {
                        let BankCountryText, SupplierRegisteredCountryText;
                        if (bankCountryValue === 'Ca') {
                          BankCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${bankCountryValue.toUpperCase()}.Text`);
                        } else {
                          BankCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${bankCountryValue}.Text`);
                        }
                        if (supplierRegisteredCountryValue === 'Ca') {
                          SupplierRegisteredCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${supplierRegisteredCountryValue.toUpperCase()}.Text`);
                        } else {
                          SupplierRegisteredCountryText = this.languageService.getValue(`dropdown.supplier-step2.Country.${supplierRegisteredCountryValue}.Text`);
                        }
                        item.hide = supplierRegisteredCountryValue.toLowerCase().trim() == bankCountryValue.toLowerCase().trim();
                        item.label = `Supplier Location { ${SupplierRegisteredCountryText} } does not match with Bank Country/Location { ${BankCountryText} }. Select any one of the Reasons-`;
                        item.required = !item.hide;
                        let BankAndRegisteredCountryControl = this.dynamicForm.formGroup.controls['BankCountryAndRegisteredCountryValidation'];
                        if (BankAndRegisteredCountryControl) {
                          if (item.hide) {
                            BankAndRegisteredCountryControl.setValue('');
                          }
                          if (!item.hide && (!BankAndRegisteredCountryControl.value || !BankAndRegisteredCountryControl.value.length)) {
                            BankAndRegisteredCountryControl.setErrors({ required: true });
                          } else {
                            BankAndRegisteredCountryControl.setErrors(null);
                          }
                        }
                      }
                    } else if (item.name == "CompanyNameAndAccountHolderNameValidation") {
                      if (accountHolderNmValue && companyNameValue) {
                        item.hide = accountHolderNmValue.toLowerCase().trim() == companyNameValue.toLowerCase().trim();
                        item.label = `Supplier Name { ${companyNameValue} } does not match with Bank Account Holder’s Name { ${accountHolderNmValue} }. Select any one of the Reasons-`;
                        item.required = !item.hide;
                        let CompanyAndAccountHolderNameControl = this.dynamicForm.formGroup.controls['CompanyNameAndAccountHolderNameValidation'];
                        if (CompanyAndAccountHolderNameControl) {
                          if (item.hide) {
                            CompanyAndAccountHolderNameControl.setValue('');
                          }
                          if (!item.hide && (!CompanyAndAccountHolderNameControl.value || !CompanyAndAccountHolderNameControl.value.length)) {
                            CompanyAndAccountHolderNameControl.setErrors({ required: true });
                          } else {
                            CompanyAndAccountHolderNameControl.setErrors(null);
                          }
                        }
                      }
                    }
                  });
                }
              }
            }
          }
        });
      }
    });
  }

  // update supplier mapping info
  private async updateSupplierMapping() {
    this.profileService.supplierModel.SupplierProfile.SupplierFinancial = trim(
      this.dynamicForm.values()
    );
    if (this.profileService.profile_step < this.profileService.current_step) {
      this.profileService.profile_step = 3;
    }
  }

  get showResubmitBtn() {
    if (this.dynamicForm) {
      if (
        !this.isView &&
        !this.dynamicForm.disabled &&
        this.dynamicForm.formGroup
      ) {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      }
    }
    return this.profileService.checkResubmit();
  }
}
