import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerService } from "src/app/services/buyer.service";
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { FileItem, FileUploader, FileLikeObject } from "ng2-file-upload";
import { config } from "src/upload_setting/app.config";
import {
  FileType,
  FileUploadError,
} from "src/app/services/file-upload.service";
import {
  DialogService,
  ButtonCommands,
  DialogType,
} from "src/app/services/dialog.service";
import { allowedMimeType } from "src/app/shared/shared";
import { environment } from "src/environments/environment";
import { SupplierService } from "src/app/services/supplier.service";
import { State } from "src/app/shared/shared";
import { cloneDeep, strToHexCharCode } from "src/app/utils/index";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { HttpService } from "src/app/services/api/http.service";
import { LoadingService } from "src/app/services/loading.service";
import { DynamicComponentService } from "src/app/dynamic-components/services/dynamic-component.service";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { CountrySpecificUploaderConfigService } from "./../../../../services/country-specific-uploader-config.service";

enum Role {
  Buyer = "buyer",
  Supplier = "supplier",
}

const fileNamePattern = /^[^]*$/;;

@Component({
  selector: "app-uploader",
  templateUrl: "./uploader.component.html",
  host: {
    "[style.width]": "config.width",
  },
  styleUrls: ["./uploader.component.sass"],
})
export class UploaderComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() disabled = true;
  @Input() maxFileSize;
  @Input() fileType;
  @Input() uploadFileList?: any;
  @Input() showProgress = false;
  @Input() description =
    environment.languageSwitch && environment.role == "supplier"
      ? this.languageService.getValue(
          "static.custom.components.uploader.description"
        )
      : "Click to add or drag your file here";
  @Input() height = "100%";
  @Input() autoUpload = false;
  @Input() multiple = false;
  @Input() isView = false;

  @Input() messageLargerMaxSize: string;
  @Input() messageInvalidType: string;

  @ViewChild("multiValueElement") multiValueElement: any;
  @ViewChild("valueElement") valueElement: any;
  @ViewChild("subLabel") subLabel: ElementRef;

  private isHide = true;
  showTooltip = false;
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  fType: any = FileType;
  isAdded = false;
  failedFile: FileLikeObject;
  errorType: FileUploadError;
  uploadingFiles?: Array<any> = [];
  maxFileNumber = 10;
  baseUrl = environment.gateway;
  conf: any;
  left: number;
  width: number;
  tempCountry = "";
  rand =Math.round((Math.random()*10000)).toString();

  constructor(
    private dialogService: DialogService,
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private auth: AuthService,
    private onBoardingService: OnBoardingService,
    private profileService: ProfileService,
    private http: HttpService,
    private loadingService: LoadingService,
    public service: DynamicComponentService,
    private languageService: LanguageService,
    private countrySpecificUploaderConfigService: CountrySpecificUploaderConfigService,
  ) {
    super();
  }

  initUploader() {
    this.maxFileSize = this.maxFileSize || config.uploadOptions.maxFileSize;
    this.conf = cloneDeep(config.uploadOptions);
    this.conf.autoUpload = this.autoUpload;
    if (this.config.templateOptions) {
      if (this.config.templateOptions.allowedMimeType) {
        this.conf.allowedMimeType = this.config.templateOptions.allowedMimeType;
      }
      if (this.config.templateOptions.messageInvalidType) {
        this.conf.messageInvalidType =
          environment.languageSwitch && environment.role == "supplier"
            ? this.languageService.getValue(
                `${this.config.languageKey}.templateOptions.messageInvalidType`
              )
            : this.config.templateOptions.messageInvalidType;
      }
    }
    if(this.config.maxFileNumber && this.config.maxFileNumber > 0 ) {
      this.maxFileNumber = this.config.maxFileNumber;
    }
    let nullObject :any={} 
    this.uploader = new FileUploader(nullObject);
  }

  checkMaxFileSize() {
    if (this.config.countrySpecificConfig && this.formGroup.get("SupplierGeographical")
      && this.formGroup.get("SupplierGeographical").value) {
      const countryId = this.formGroup.get("SupplierGeographical").value;
      const supplierGeographical: number = Number(countryId);
      const { sizeInBytes, configFound } =
      this.countrySpecificUploaderConfigService.initConfig(this.config, supplierGeographical);

      if (configFound)
        this.maxFileSize = sizeInBytes;
    }
  }

  async ngOnInit() {
    // console.log("Uploader ngOnInit Called - this.value", this.value);
    if (this.value) {
      this.uploadingFiles = this.value;
      this.uploadingFiles.map((uploadedFile) => {
        uploadedFile.isUploading = false;
      });
    }
    this.initUploader();
    const tempCountryInfo = this.formGroup.get("Country");
    if (tempCountryInfo) {
      this.tempCountry = tempCountryInfo.value;
    }

    this.subscribeToValueChange(this.config.name, (item: []) => {
      if (!this.uploadingFiles.length  && item && item.length){
          this.uploadingFiles = item
      }
      if (item && item.length === 0) {
        this.uploadingFiles = [];
      }
    });
    if (this.shouldClearUploader(this.config.name)) {
      this.subscribeToValueChange("Country", (item: []) => {
        const newCountry = this.formGroup.get("Country").value;
        if (
          (this.tempCountry != "IT" && newCountry == "IT") ||
          (this.tempCountry == "IT" && newCountry != "IT")
        ) {
          this.uploadingFiles = [];
          const formControl = this.formGroup.get(this.config.name);
          formControl.patchValue(this.uploadingFiles, {
            onlySelf: true,
            emitEvent: true,
          });
          formControl.setValidators(
            this.service.validatorFactory.createValidators(
              this.config,
              this.context
            )
          );
          formControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: true,
          });
          this.clearValues();
        }
        this.tempCountry = newCountry;
      });
    }

    this.context.on(this.config.name, (fileList: any) => {
      const formControl = this.formGroup.get(this.config.name);
      if (!formControl) {
        return;
      }
      if (fileList !== "") {
        formControl.patchValue(fileList, { onlySelf: true, emitEvent: true });
        formControl.clearValidators();
        formControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        formControl.patchValue(this.uploadingFiles, {
          onlySelf: true,
          emitEvent: true,
        });
        formControl.setValidators(
          this.service.validatorFactory.createValidators(
            this.config,
            this.context
          )
        );
        formControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    });

    this.uploader.onAfterAddingAll = async (newFiles: FileItem[]) => {
      const existingFiles = this.uploadingFiles.filter((uf) =>
        newFiles.find((nf) => nf.file.name === uf.name)
      );
      if (existingFiles && existingFiles.length > 0) {
        return;
      }
      const fileNum = this.maxFileNumber - this.uploadingFiles.length;
      newFiles = newFiles.slice(0, fileNum);
      // fileNamePattern.test(this.requestorDetailsInfo.SupplierPOCEmail)
      for (const file of newFiles) {
        const chunkFile = file.file.name.split(".");
        if (file.file.type === "" && chunkFile[chunkFile.length - 1] ==="java") {
          file.file.type = "java";
        }
        if(this.conf.allowedMimeType.indexOf(file.file.type) === -1 || (file.file.size > this.maxFileSize)) {
          this.addingFileFailed(file.file)
            if (newFiles.length === this.uploader.queue.length) {
              newFiles = [];
              this.uploader.queue = [];
            } else {
              newFiles = [];
              this.uploader.queue = this.uploader.queue.slice(1, 1);
            }
            if (this.uploadingFiles.length === 0) {
              this.context.emit(this.config.name, "");
            }
            return;
          } 
        if (!fileNamePattern.test(file.file.name)) {
          if (newFiles.length === this.uploader.queue.length) {
            newFiles = [];
            this.uploader.queue = [];
          } else {
            newFiles = [];
            this.uploader.queue = this.uploader.queue.slice(1, 1);
          }
          if (environment.languageSwitch && environment.role == "supplier") {
            await this.dialogService.dialog(
              this.languageService.getValue(
                "static.shared.components.file_upload.errorMessage"
              ),
              ButtonCommands.Close,
              DialogType.alert,
              this.languageService.getValue(
                "static.custom.components.uploader.dialog_header"
              )
            );
          } else {
            await this.dialogService.dialog(
              `The attachment could not be uploaded. Please use a file name containing only the following characters: 'a-z' 'A-Z' '0-9'  '-' or '_' `,
              ButtonCommands.Close,
              DialogType.alert,
              "Please Note"
            );
          }
          if (this.uploadingFiles.length === 0) {
            this.context.emit(this.config.name, "");
          }
          return;
        }
        if (
          !!this.config.templateOptions &&
          !!this.config.templateOptions.allowedMimeType &&
          !this.config.templateOptions.allowedMimeType.includes(
            "text/comma-separated-values"
          )
        ) {
          if (file.file.name.substring(file.file.name.length - 4) === ".csv") {
            newFiles = [];
            this.uploader.queue = [];
            if (environment.languageSwitch && environment.role == "supplier") {
              await this.dialogService.dialog(
                this.languageService.getValue(
                  "static.custom.components.uploader.errorMessage3"
                ),
                ButtonCommands.Close,
                DialogType.alert,
                this.languageService.getValue(
                  "static.custom.components.uploader.dialog_header"
                )
              );
              return;
            } else {
              await this.dialogService.dialog(
                `The file you tried to upload is not supported,please see the help icon besides upload field to know what are supported file types.`,
                ButtonCommands.Close,
                DialogType.alert,
                "Please Note"
              );
              return;
            }
          }
        }
      }
      const newUploaderQueue = this.uploader.queue.filter(
        (fileItem: FileItem, index) => {
          if (index < newFiles.length) {
            return true;
          }
        }
      );
      this.uploader.queue = [];
      this.uploader.queue = newUploaderQueue;
      newFiles.forEach((file) => {
        const fileImg = this.initFileType(file);
        this.uploadingFiles.push({
          name: file.file.name,
          imgUrl: fileImg,
          isUploading: true,
        });
      });
      //  this.context.emit('isDisabledSaveBtn', {})

      let resFileList: any;
      if (environment.role === Role.Buyer) {
        resFileList = await this.buyerService.buyerFileUpload(
          this.onBoardingService.supplierCode,
          "files",
          newFiles
        );
      } else {
        resFileList = await this.supplierService.supplierFileUpload(
          this.profileService.supplierCode,
          "files",
          newFiles
        );
      }
      // console.log(
      //   "uploader valid after => ",
      //   this.formGroup.get(this.config.name)
      // );
      resFileList.data.map((fileRes) => {
        this.uploadingFiles.map((uploadedFile) => {
          if (uploadedFile.name === fileRes.OriginalName) {
            uploadedFile.filePath = fileRes.Location;
            uploadedFile.key = fileRes.BucketKey;
            uploadedFile.isUploading = false;
          }
        });
      });

      this.context.emit(this.config.name, cloneDeep(this.uploadingFiles));
    };

    this.uploader.onCompleteAll = () => {};
    this.rand =Math.round((Math.random()*10000)).toString();
  }

  initFileType(file) {
    let type;
    const chunkFile = file.file.name.split(".");
    if (file.file.type === allowedMimeType.PDF) {
      type = "pdf";
    }
    if (
      file.file.type === allowedMimeType.XLS ||
      file.file.type === allowedMimeType.CSV ||
      file.file.type === allowedMimeType.XLSX
    ) {
      type = "xls";
    }
    if (
      file.file.type === allowedMimeType.DOC ||
      file.file.type === allowedMimeType.DOCX
    ) {
      type = "doc";
    }
    if (
      file.file.type === allowedMimeType.PPT ||
      file.file.type === allowedMimeType.PPTX
    ) {
      type = "ppt";
    }
    if (file.file.type === allowedMimeType.RTF) {
      type = "rtf";
    }
    if (
      file.file.type === allowedMimeType.IMG ||
      file.file.type === allowedMimeType.PNG
    ) {
      type = "img";
    }
    if (file.file.type === allowedMimeType.ODT) {
      type = "odt";
    }
    if (file.file.type === "" && chunkFile[chunkFile.length - 1] === "msg") {
      type = "msg";
    }
    if (file.file.type === allowedMimeType.TXT) {
      type = "txt";
    }
    
    return `/assets/images/files/${type}_file.svg`;
  }

  addingFileFailed (failed: FileLikeObject) {
    this.failedFile = failed;
    this.clearValues();
    this.showError(this.failedFile);
  }; 
  async showError(errorFile) {
    this.messageInvalidType = "";
    this.messageLargerMaxSize = "";
    if (errorFile.size > this.maxFileSize) {
      this.errorType = FileUploadError.SizeError;
      if (environment.languageSwitch && environment.role == "supplier") {
        this.messageLargerMaxSize =
          this.messageLargerMaxSize ||
          this.languageService.getValue(
            "static.custom.components.uploader.messageLargerMaxSize1"
          ) +
            " " +
            this.maxFileSize / 1024 / 1024 +
            this.languageService.getValue(
              "static.custom.components.uploader.messageLargerMaxSize2"
            ) +
            " " +
            this.maxFileSize / 1024 / 1024 +
            this.languageService.getValue(
              "static.custom.components.uploader.messageLargerMaxSize3"
            ) +
            "<br/>";
      } else {
        this.messageLargerMaxSize =
          this.messageLargerMaxSize ||
          "The file you tried to upload is more than " +
            this.maxFileSize / 1024 / 1024 +
            "MB, please try to upload files which are less than or equal to " +
            this.maxFileSize / 1024 / 1024 +
            "MB.<br/>";
      }
    }
    if (
      this.conf.allowedMimeType.indexOf(errorFile.type) === -1 ||
      errorFile.type === ""
    ) {
      this.errorType = FileUploadError.TypeError;
      if (this.conf.messageInvalidType) {
        this.messageInvalidType = this.conf.messageInvalidType;
      } else if (this.context.getValue("isICEG")) {
        if (environment.languageSwitch && environment.role == "supplier") {
          this.messageInvalidType =
            this.messageInvalidType ||
            this.languageService.getValue(
              "static.custom.components.uploader.errorMessage2"
            );
        } else {
          this.messageInvalidType =
            this.messageInvalidType ||
            `Please note the file type you are trying to upload is not supported. Please ensure all documents you upload are PDF or JPEG files. `;
        }
      }
      if (environment.languageSwitch && environment.role == "supplier") {
        this.messageInvalidType =
          this.messageInvalidType ||
          this.languageService.getValue(
            "static.custom.components.uploader.errorMessage3"
          );
      } else {
        this.messageInvalidType =
          this.messageInvalidType ||
          `The file you tried to upload is not supported,
                                    please see the help icon besides upload field to know what are supported file types.`;
      }
    }

    if (this.messageLargerMaxSize) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.messageLargerMaxSize,
          ButtonCommands.Close,
          DialogType.alert,
          this.languageService.getValue(
            "static.custom.components.uploader.header"
          )
        );
      } else {
        await this.dialogService.dialog(
          this.messageLargerMaxSize,
          ButtonCommands.Close,
          DialogType.alert,
          "Unsupported File Size"
        );
      }
      return;
    }
    if (
      this.messageInvalidType ||
      (this.messageInvalidType && this.messageLargerMaxSize) ||
      errorFile.type === ""
    ) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.messageInvalidType,
          ButtonCommands.Close,
          DialogType.alert,
          this.languageService.getValue(
            "static.custom.components.uploader.type"
          )
        );
      } else {
        await this.dialogService.dialog(
          this.messageInvalidType,
          ButtonCommands.Close,
          DialogType.alert,
          "Unsupported File Types"
        );
      }
      return;
    }
  }

  fileOverBase(event) {
    this.hasBaseDropZoneOver = event;
  }

  fileDropOver(event) {}

  async downloadFile(fileKey, fileName) {
    let role = "";
    const access_token = `Bearer ${this.auth.passport.access_token}`;
    const state = environment.role;
    let downloadPath: string;
    if (state === State.buyer && this.auth.passport.buyer) {
      role = this.auth.passport.buyer.RoleCode;
      downloadPath = "buyer/file/download";
    } else if (state === State.supplier && this.auth.passport.supplierMember) {
      role = this.auth.passport.supplierMember.RoleCode;
      downloadPath = "supplier/file/download";
    }
    const params = strToHexCharCode(
      `key=${fileKey}&Authorization=${access_token}&state=${state}&role=${role}`
    );
    // const url = `${this.baseUrl}/${downloadPath}?fileCode=${params}`
    // window.open(url, '_blank')
    const url = `${this.baseUrl}/${downloadPath}`;
    this.loadingService.openLoading(url);
    this.http
      .PostBlobPromise(url, { fileCode: params }, "blob")
      .then((data: BlobPart) => {
        const blob = new Blob([data]);
        // for ie
        if ("msSaveOrOpenBlob" in navigator) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loadingService.closeLoading();
        }
      });
  }

  deleteFile(index) {
    this.uploadingFiles.splice(index, 1);
    this.uploader.queue.splice(index, 1);
    this.context.emit(this.config.name, "");
    if (!this.uploadingFiles.length) {
      this.control.patchValue([], { onlySelf: true, emitEvent: true });
    }
    this.clearValues();
    const formControl = this.formGroup.get(this.config.name);
    if (formControl) {
      formControl.setValidators(
        this.service.validatorFactory.createValidators(
          this.config,
          this.context
        )
      );
      formControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }
    // TODO
    // this.context.emit(this.config.uploader.addedFilesName, this.uploadingFiles)
  }

  clearValues() {
    if (this.valueElement) {
      this.valueElement.nativeElement.value = null;
    }
    if (this.multiValueElement) {
      this.multiValueElement.nativeElement.value = null;
    }
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
  imgMouseover($event) {
    this.showTooltip = true;
    if ($event.clientX < 450) {
      this.left = 1.5;
    } else {
      this.left = -230;
    }
  }

  toggle() {
    this.isHide = !this.isHide;
  }

  @HostListener("click", ["$event"])
  documentClick(event) {
    if (this.subLabel) {
      const subLabelElements =
        this.subLabel.nativeElement.getElementsByTagName("a");
      if (subLabelElements && subLabelElements.length) {
        for (const i in subLabelElements) {
          if (event.target === subLabelElements[i]) {
            const aTag = subLabelElements[i];
            const fileName = aTag.getAttribute("alt");
            if (!fileName) {
              break;
            }
            this.downloadFile(
              `buyer/accenture/requestFormFiles/tax/${fileName}`,
              fileName
            );

            break;
          }
        }
      }
    }
    event.stopPropagation();
  }
  shouldClearUploader(controlName: string) {
    if (
      controlName === RefreshUploader.UploadCompanyIncorporation ||
      controlName === RefreshUploader.FederalTaxDepartmentUploader ||
      controlName === RefreshUploader.PayrollTaxesCertificationUploader ||
      controlName === RefreshUploader.RCTOInsuranceUploader
    ) {
      return true;
    }
    return false;
  }
}

enum RefreshUploader {
  UploadCompanyIncorporation = "UploadCompanyIncorporation",
  FederalTaxDepartmentUploader = "FederalTaxDepartmentUploader",
  PayrollTaxesCertificationUploader = "PayrollTaxesCertificationUploader",
  RCTOInsuranceUploader = "RCTOInsuranceUploader",
}
