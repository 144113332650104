import { Component, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { cloneDeep } from "src/app/utils";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { ComponentConfig } from "../../../../dynamic-components/ComponentConfig";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { environment } from "src/environments/environment";

const MAX_ROW_SIZE = 10;
@Component({
  selector: 'app-certification-and-license-list-of-collapse',
  templateUrl: './certification-and-license-list-of-collapse.component.html',
  styleUrls: ['./certification-and-license-list-of-collapse.component.sass'],
  host: {
    "[style.width]": "config.width",
  },
})
export class CertificationAndLicenseListOfCollapseComponent 
extends BaseComponent
implements OnInit{
  certificationAndLicenseListVO = [];
  certificationAndLicenseListFromArray: UntypedFormArray;
  certificationAndLicenseList = [];
  hidePlusBbutton = false;
  
  constructor(
    private profileService: ProfileService,
  ) {
    super();
  }

  ngOnInit() {
    this.certificationAndLicenseListFromArray = this.getcertificationAndLicenseListFormArray();
    this.certificationAndLicenseList = this.getcertificationAndLicenseList() || [];
    this.initCertificationAndLicenseList();
  }

  get isSupplierOnboardingForm(): boolean {
    return environment.role === 'supplier';
  }
  
  getcertificationAndLicenseListFormArray(): UntypedFormArray {
    return this.formGroup.get(this.config.name) as UntypedFormArray;
  }

  getcertificationAndLicenseList(): Array<any> {
    if(!this.isSupplierOnboardingForm) {
      return this.context.getValue("parentModel").RequestForm
        .RequestorDetailsInfo.MixCertificationAndLicenseList;
    } else {
      return this.context.getValue("parentModel").SupplierProfile
        .SupplierGeneral.MixCertificationAndLicenseList;
    }
  }

  private createCertificationAndLicenseFormGroup(index: number): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});
    const idFormControl = new UntypedFormControl();
    idFormControl.patchValue(index);
    formGroup.addControl("certificationAndLicenseListId", idFormControl);
    return formGroup;
  }

  private createCertificationAndLicenseDTO(
    formGroup: UntypedFormGroup,
    certificationAndLicenseInfo: Array<any> = []
  ): any {
    const context = new DynamicContext();
    context.parent = this.context;
  
    return {
      certificationAndLicenseInfo: certificationAndLicenseInfo,
      formGroup: formGroup,
      context: context,
      config: cloneDeep(this.config.fieldset),
      dashboradConfig: this.initViewConfig(
        certificationAndLicenseInfo,
        cloneDeep(this.config.fieldset)
      ),
      // iscertificationAndLicenseInfoShow: true,
    };
  }

  initCertificationAndLicenseList() {
    if(this.certificationAndLicenseList.length > 0) {
      this.certificationAndLicenseList.forEach((certificationAndLicenseInfo) => {
        const certificationAndLicenseListformGroup = this.createCertificationAndLicenseFormGroup(Math.random());
        this.certificationAndLicenseListFromArray.push(
          certificationAndLicenseListformGroup
        );

        const certificationAndLicenseInfoDTO = this.createCertificationAndLicenseDTO(certificationAndLicenseListformGroup, certificationAndLicenseInfo);
        this.certificationAndLicenseListVO.push(
          certificationAndLicenseInfoDTO
        );
      });
    } else {
      const certificationAndLicenseListformGroup =this.createCertificationAndLicenseFormGroup(Math.random());
      this.certificationAndLicenseListFromArray.push(
        certificationAndLicenseListformGroup
      );
      const certificationAndLicenseInfoDTO = this.createCertificationAndLicenseDTO(certificationAndLicenseListformGroup);
      this.certificationAndLicenseListVO.push(
        certificationAndLicenseInfoDTO
      );
    }      
    
  }

  initCertificationAndLicenseListByclicking() {
    // const index = this.certificationAndLicenseListFromArray.length;
    const index = Math.random();
    const certificationAndLicenseListformGroup = this.createCertificationAndLicenseFormGroup(index);
    this.certificationAndLicenseListFromArray.push(
      certificationAndLicenseListformGroup
    );
    const certificationAndLicenseInfoDTO = this.createCertificationAndLicenseDTO(certificationAndLicenseListformGroup);
    this.certificationAndLicenseListVO.push(
      certificationAndLicenseInfoDTO
    );
  }

  initViewConfig(
    certificationAndLicenseInfo: any,
    config: Array<ComponentConfig>
  ) {
    const conf = new Array<ComponentConfig>();
    config.forEach((p) => {
      if (p.fieldset) {
        p.fieldset.forEach((z) => {
          if (
            certificationAndLicenseInfo[z.name] instanceof Array &&
            certificationAndLicenseInfo[z.name].length > 0
          ) {
            conf.push(z);
          }
          if (
            !(certificationAndLicenseInfo[z.name] instanceof Array) &&
            certificationAndLicenseInfo[z.name]
          ) {
            conf.push(z);
          }
        });
      } else {
        if (
          certificationAndLicenseInfo[p.name] instanceof Array &&
          certificationAndLicenseInfo[p.name].length > 0
        ) {
          conf.push(p);
        }
        if (
          !(certificationAndLicenseInfo[p.name] instanceof Array) &&
          certificationAndLicenseInfo[p.name]
        ) {
          conf.push(p);
        }
      }
    });
    return conf;
  }

  isNotViewAndformView(): boolean {
    if (
      this.context.mode !== "view" &&
      this.context.mode !== "formView"
    ) {
      return true;
    } else {
      return false;
    }
  }

  addCertificateAndLicenseRow() {
    if(this.certificationAndLicenseListFromArray.length < MAX_ROW_SIZE) {
      this.initCertificationAndLicenseListByclicking();
    }
  }

  removeCertificateAndLicenseRow(certificationAndLicenseInfo: any) {
    if (this.certificationAndLicenseListFromArray.length === 0) {
      return;
    }
    
    const removedId = certificationAndLicenseInfo.formGroup.get(
      "certificationAndLicenseListId"
    ).value;
    this.certificationAndLicenseListVO =
      this.certificationAndLicenseListVO.filter(
        (p) =>
          p.formGroup.get("certificationAndLicenseListId").value !== removedId
      );
    const index =
      this.certificationAndLicenseListFromArray.controls.findIndex(
        (p) => p.get("certificationAndLicenseListId").value === removedId
      );
    this.certificationAndLicenseListFromArray.removeAt(index);
  }
}
