export interface SupplierProfile {
  SupplierGeneral?: SupplierGeneral;
  SupplierOrganization?: SupplierOrganization;
  SupplierFinancial?: SupplierFinancial;
  SupplierDeclaration?: SupplierDeclaration;
  SupplierCompliance?: SupplierCompliance;
}

export function createSupplierProfile(): SupplierProfile {
  return {
    SupplierGeneral: {},
    SupplierOrganization: {},
    SupplierFinancial: {},
    SupplierDeclaration: {},
    SupplierCompliance: {},
  };
}

/// ############ supplier model#############
export interface SupplierGeneral {
  MixGeneralComment?: string
  CompanyCode?: string;
  CompanyName?: string;
  CompanyName2?: string;
  CompanyName3?: string;
  CompanyName4?: string;
  CompanyLegalName?: string;
  RegisteredCountry?: string;
  AnotherAddress?: string;
  StreetAds?: string;
  StreetAds2?: string;
  StreetAds3?: string;
  POBox?: string;
  City?: string;
  State?: string;
  District?: string;
  RegionCd?: string;
  Country?: string;
  ZipCd?: string;
  BranchCode?: string;
  POBoxZipCd?: string;
  Phone?: string;
  MPhone?: string;
  PrimaryContactNm?: string;
  PrimaryContactFirstNm?: string;
  PrimaryContactLastNm?: string;
  PrimaryContactPhone?: any;
  PrimaryContactEmail?: string;
  SecondaryContact?: string;
  SecondaryContactEmail?: string;
  SecondaryContactPhone?: string;
  ServiceOffered?: string;
  SupplierBusinessWebsite?: string;
  MixCertificationAndLicenseList?: Array<CertificationAndLicenseInformation>;
  GeographiesServed?: string;
  DateFounded?: string;
  TradeOrPM?: string;
  adInEnglish?: string;
  FaxNumber?: string;
  SoftwareOrWBTool?: string;
  AddGeneralAttachments?: any;
  accountsReceivablePOC?: string;
  accountsReceivablePOCEmail?: string;
  CompanyKnownByOtherName?: string;
  CaState?: string;
  manageDirectorOwner?: string;
  manageDirectorOwnerTrue?: string;
  UploadCompanysAddress?: any;
}

export interface SupplierOrganization {
  SupplierRegisteredCountry?: string;
  UsaEmpIdNum?: string;
  TaxOfficeName?: string;
  BusinessRegistrationNb?: string;
  BusinessRegistrationType?: string;
  GSTRegistrationNb?:string;
  Supplier_DUNS_Nb?: string;
  Trade_DBA_Parent_DUNS_Nb?: string;
  UploadRegistrationDocuments?: any;
  SampleATPCompliant?: any;
  SampleATPCompliant2?: any;
  IsSupplierAnIndividualOrCompany?: string;
  IsSupplierCompanyClassify?: string;
  TypeOfCompany?: string;
  uploadIncorporationCertificate?: any;
  CompanyDetails?: string;
  IsDoesSupplier?: string;
  DBA_BusinessNm?: string;
  UploadFinancialCopy?: any;
  UploadVatDocuments?: any;
  SupplierAnnualRevenue?: string;
  BusinessSize?: string;
  LongOfSupplier?: string;
  SupplierConductBusiness?: string;
  BuyerStepTBI_BDA_1?: string;
  SupplierGeographical?: string;
  BRN?: string;
  VATNb?: string;
  TaxIDSteuernummer?: string;
  CompanyVATNumber?: string;
  companyTANNumber?: string;
  CompanyPANNumber?: string;
  SIRET_Nb?: string;
  SIRET_Nb_RCS?: string;
  GstVendorClassificationForIN?: string;
  GstVendorClassification?: string;
  GST_Registration_Nb?: string;
  IsFromPayingVAT?: string;
  VATNumberForMwST?: string;
  GoodsandServiceTaxNumber?: string;
  ProvincialSalesTaxPSTNumber?: string;
  QuebecSalesTaxQSTNumber?: string;
  HarmonizedSalesTaxHSTNumber?: string;
  UploadCaRegistrationDocuments?: any;
  UploadRegistrationDocumentsForIndia?: any;
  UploadCompanyRegistrationDocuments?: any;
  UploadGSTRegistrationCertificate?: any;
  ProvideIndicate?: string;
  ConfirmPerformed?: string;
  EnterpriseType?: string;
  AdditionalGSTRegistration?: string;
  AdditionalGSTRegistrationList?: Array<AdditionalGSTRegistrationInformation>;
  SpecialEmploymentCertificate?: any;
  SpecialEmploymentCenter?: string;
  CommercialRelationship?: string;
  DatesAndScopeThereof?: string;
}

export interface AdditionalGSTRegistrationInformation {
  additionalGSTRegistrationInformationId: number;
  StreetAddress: string;
  CountryLocation: string;
  AdditionalState?: string;
  City?: string;
  ZipCode?: string;
  AdditionalGSTRegistrationNumber?: string;
  AdditionalGSTVendor?: Array<string>;
  AddGSTCertificateAttachments?: any;
}

export interface SupplierFinancial {
  PreferredOrderingMethod?: string;
  EnterExplainInfo?: string;
  IsSupplierNetworkEnabled?: string;
  PreferredOrderingMethodDetails?: string;
  PaymentTerms?: string;
  RequestorEmailAddress?: string;
  CommentFieldAndAttachment?: string;
  CustomAttachments?: any;
  DoesSupplierAllowPartialPayments?: string;
  PaymentTermsField?: string;
  PaymentBankingInformationList?: Array<PaymentBankingInformation>;
  ThirdPartyPaymentInformationList?: Array<ThirdPartyPaymentInformation>;
  LatestBalanceSheetAttachment?: any;
  CurrencyUsedForTurnover?: string;
  InsuranceCertificationDate?: string;
  BankCountryAndRegisteredCountryValidation?: string
  InputOtherForBankCountryAndRegisteredCountry?: string
  CompanyNameAndAccountHolderNameValidation?: string
  InputOtherForCompanyNameAndAccountHolderNameValidation?: string
}

export interface PaymentBankingInformation {
  paymentBankingInformationId: number;
  BankCountry: string;
  PaymentMethod?: string;
  BankNm?: string;
  BankAds?: string;
  BankBranchNm?: string;
  AccountHolderNm?: string;
  Account?: string;
  BankKey?: string;
  IBAN?: string;
  BIC_SwiftCd?: string;
  ABARouting?: string;
  SwiftCd?: string;
  RoutingNumberDomestic?: string;
  BankInstitutionNumber?: string;
  BankTransitNumber?: string;
  CurrencyUsedForPayments?: Array<string>;
  AddFinancialAttachments?: any;
  AddAgreementAttachments?: any;
  isPESONetregistered?: string;
  isOtherPESONetregistered?: string;
  iSHaveIntermediaryBankAccount?: string;
  IntermediaryBankCountry: string;
  IntermediaryPaymentMethod?: string;
  IntermediaryBankNm?: string;
  IntermediaryBankAds?: string;
  IntermediaryBankBranchNm?: string;
  IntermediaryAccountHolderNm?: string;
  IntermediaryAccount?: string;
  IntermediaryBankKey?: string;
  IntermediaryIBAN?: string;
  IntermediaryBIC_SwiftCd?: string;
  IntermediaryABARouting?: string;
  IntermediarySwiftCd?: string;
  IntermediaryRoutingNumberDomestic?: string;
  IntermediaryBankInstitutionNumber?: string;
  IntermediaryBankTransitNumber?: string;
  IntermediaryCurrencyUsedForPayments?: Array<string>;
  IntermediaryAddFinancialAttachments?: any;
  IntermediaryAddAgreementAttachments?: any;
  IntermediarySwiftCdthree?: string;
  SwiftCdthree?: string;
}

export interface ThirdPartyPaymentInformation {
  thirdPartyPaymentId: number;
  thirdPartyPaymentInformationId: number;
  ThirdPartyType: string;
  ThirdPartyCompanyName?: string;
  ThirdPartyCompanyAddress?: string;
  ThirdPartyPhoneNumber?: string;
  ThirdPartyEmailAddress?: string;
  ThirdPartyTax?: string;
  ThirdPartyTaxAttachments?: any;
  ThirdPartyBankCountry: string;
  ThirdPartyBankNm?: string;
  ThirdPartyBankAds?: string;
  ThirdPartyAccountHolderNm?: string;
  ThirdPartyAccount?: string;
  ThirdPartyBankKey?: string;
  ThirdPartyIBAN?: string;
  ThirdPartyBIC_SwiftCd?: string;
  ThirdPartyABARouting?: string;
  ThirdPartyCurrencyUsedForPayments?: string;
  ThirdPartyPaymentMethod?: string;
  ThirdPartyBankBranchNm?: string;
  ThirdPartyAddAgreementAttachments?: any;
  ThirdPartyAddFinancialAttachments?: any;
  ThirdPartyAddressEvidenceAttachments?: any;
}

export interface SupplierCompliance {
  CompanyOwnership?: string;
  EnterpriseScale?: string;
  GenderEquality?: string;
  Goal?: Array<string>;
  DiversifiedEnterprises?: string;
  SmallMediumSizedEnterprisesPercentage?: string;
  DifferentSuppliersPercentage?: string;
  UploadCertificate?: any;
  DiversityCertificate?: string;
  DateOfIssue?: string;
  DiversityType?: string;
  DiversityDetail?: string;
  StepFourComment?: string;

  ComplianceLocalPolicies?: string;
  ProvidingLegalConnections?: string;
  UploadDetailsMaterial?: any;
  SignatureInternationalStandards?: string;
  SignatureInternationalStandardsDetail?: string;
  ContractTermsProvideToSuppliers?: string;
  HaveHumanRightsRisks?: string;
  RisksLearnings?: string;
  OrganizationsToSupportRaising?: string;
  ProvidingSupportOrganizations?: string;
  OrganizationsOnLaboursStandards?: string;
  HaveHumanRights?: string;
  HaveHumanRightsAttachments?: any;
  HaveHumanRightsYes?: string;
  HaveHumanRightsYesReport?: string;
  HaveConductedIdentityVerification?: string;
  CanTerminateEmployment?: string;
  IsRightTerminateEmployment?: string;
  HireViolation?: string;
  HireViolationYes?: string;
  CanConfirmCompanyInfo?: string;
  HaveAntiCorruptionPolicy?: string;
  HaveBCMPlan?: string;
  HaveSubjectLegalActions?: string;
  ExplainProcesses?: string;
  DetailsMaterial?: string;

  EnvironmentalPolicy?: string;
  UnitedNationsGlobalCompact?: string;
  ManagementAssessment?: string;
  InputEnvironmentalEffect?: string;
  HaveSolution?: string;
  IsCompanyCertified?: string;
  // linkage  requestor-detail SupplierGeographical (if requestor-detail SupplierGeographical ){ hide rq 7}
  SupplierGeographical?: string;

  SupplierBBBEECertificate?: any;
}

export interface SupplierDeclaration {
  IsConflictOfInterest?: string;
  IsInformationCorrect?: string;
  IsConflictOfInterestC?: string;
  SubmittedBy?: string;
  IsAgreed?: string;
  AddDeclarationAttachments?: any;
}

export interface CertificationAndLicenseInformation {
  CertificateAndLicenseAttachments?: any;
  CertificationAndLicenseExpirationDate?: string;
  CertificationAndLicenseDocumentName?: string;
}
