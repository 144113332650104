// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cert-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  max-width: 100%;
}

.form-element {
  flex: 1;
}

.delete-icon {
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.btn-add-document {
  border-radius: 0.3rem;
  border: dashed 1px rgba(31, 31, 53, 0.3);
  background-color: #fff;
  width: 36.5%;
  margin-left: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
